import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2019 The Weird Ones Are Wolves',
	date: '2021-09-06',
	image: require('./2019-the-weird-ones-are-wolves@2x.png'),
	body: (
		<>
			<p>
				A blend of 94% Cabernet Franc and 6% Petit Manseng. A deep baked bluefruit nose with cedar and baking
				spices leads up to an equally warm palette. This is our meatiest wine we have made thus far. A true red
				meat monster with soft tannins for days. Decant for an hour or more, chill just a touch. 326 cases
				produced.
			</p>
			<p>
				<strong>FRUIT SOURCES</strong>
				<br />
				Baer Ridge Vineyard in Timberville, grower Jody Dodson; Sherman Ridge Vineyard n Woodstock, grower
				Sunset Hills
			</p>
			<p>
				<strong>WINEMAKING</strong>
				<br />
				Both grapes were cofermented with 20% Whole cluster, the Petit Manseng being all whole cluster. Aged for
				11 months in neutral French 500L and 228L barrels. Bottled unfiltered August 2020.
			</p>
			<p>
				<strong>CHEMISTRY</strong>
				<br />
				pH: 3.88
				<br />
				TA: 4.4 g/L
				<br />
				Free SO2 at bottling: 22 ppm
				<br />
				Alcohol: 13.4% abv
				<br />
				RS: 0.17 g/L
				<br />
				Malic Acid: 0.02 g/L
				<br />
				Acetic acid at bottling: 0.59 g/L
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
