import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2020 Hintermen',
	date: '2022-09-27',
	image: require('./2020-hintermen@2x.png'),
	body: (
		<>
			<p>
				A blend of 59% Petit Manseng and 41% Vidal Blanc. We gotta be honest, it looks like Sauternes. Like
				someone took some honey, added some marigold and smashed ‘em together into a magical yellow nectar. With
				a face like that, it has a lot to live up to. The nose does not disappoint here and it's exploding out
				of the glass. Sweet pear, lychee, jasmine, honeycomb… we could go on. All that sounds like you’re in for
				a terribly unbalanced glass of high octane fruit juice but NAY!!! The palate, while certainly having
				texture, is quite balanced with a brilliant acidity that cleans up any lagging fruit elements. Careful
				of letting this get too warm as it will develop a lot of richness. Bravo! Dear Ben, Bravo! 95 cases
				produced.
			</p>
			<p>
				<strong>FRUIT SOURCES</strong>
				<br />
				Sherman Ridge vineyard in Woodstock, grower Sunset Hills
			</p>
			<p>
				<strong>WINEMAKING</strong>
				<br />
				These two grapes were fermented together in large (500 to 600L), neutral puncheons. The alcoholic
				fermentation lasted a full 16 months, during which time the wine went through malolactic. It was bottled
				unfined/unfiltered. As this wine evolves and our access to Riesling diminishes, this year we embraced a
				vineyard co-ferment of Shenandoah Valley Petit Manseng and Vidal blanc from Sherman Ridge. The Vidal
				moderates the alcohol but steps back a bit aromatically, making this a Manseng driven wine.
			</p>
			<p>
				<strong>CHEMISTRY</strong>
				<br />
				pH: 3.48
				<br />
				TA: 5.7 g/L
				<br />
				Free SO2 at bottling: 6 ppm
				<br />
				Alcohol: 13.9% abv
				<br />
				RS: 2.35 g/L
				<br />
				Malic Acid: 0.06 g/L
				<br />
				Acetic acid at bottling: 0.43 g/L
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
