import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2015 The Weird Ones Are Wolves',
	date: '2017-11-01',
	image: require('./2015-the-weird-ones-are-wolves@2x.png'),
	body: (
		<>
			<p>
				A blend of 97% Cabernet Franc and 3% Petit Manseng. This one’s for the weirdos. For the wolves in
				wolves’ clothings (paired with the occasional trench coat). For the ones who feel more comfortable
				walking through a dark alley than a bustling city street. After all, a wolf doesn’t concern itself with
				the opinions of sheep.
			</p>
			<p>
				<i>The Weird Ones are Wolves</i> is a bit more serious. The blend is a subtle nod to Cote-Rotie. The
				Cabernet Franc leaps out of the glass with its dry cranberry fruit while the honey of the Manseng rounds
				out the edges. The blend brings an elegant and lush palate that drinks more like a traditional red,
				while soft tannins and bright acid keep things lively. It’s ready for a long life in the cellar but
				perfect for dinner tonight. 75 cases produced.
			</p>
			<p>
				<strong>FRUIT SOURCES</strong>
				<br />
				Baer Ridge Cabernet Franc from the Shenandoah Valley. Early Mountain Petit Manseng from Madison,
				Virginia (Madison County).
			</p>
			<p>
				<strong>WINEMAKING</strong>
				<br />
				Simple. 20% whole cluster. Fermented with ambient yeasts. Malolactic and aging in old 228L French
				barrels. No racking. Bottled unfiltered August 2016.
			</p>
			<p>
				<strong>CHEMISTRY</strong>
				<br />
				pH: 3.8
				<br />
				TA: 5.1 g/L
				<br />
				Free SO2 at bottling: 18 ppm
				<br />
				Alcohol: 13.5% abv
				<br />
				RS: 0.19 g/L (0.02%)
				<br />
				Malic Acid: 0.04 g/L
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
