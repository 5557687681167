import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2022 Goodbye Horses',
	date: '2024-05-02',
	image: require('./2022-goodbye-horses@2x.png'),
	body: (
		<>
			<p>
				84% Riesling and 16% Petit Manseng. Thanks be to whatever (if anything) created the riesling grape, and
				thanks to them for getting it to us. This is a damn ripper. Like chewing on the rind of lime while
				sucking on a sour apple Jolly Rancher. This joint is acidic baby! Almost feels acidified, but no no no,
				not today devil. We rollin deep in the crisp, shivering light of day and ridin' this horse all the way
				to refreshment town. Yee haw, bitches! 189 cases produced.
			</p>
			<p>
				<strong>FRUIT SOURCES</strong>
				<br />
				Oxeye Vineyard in Swoope and Verona Farm Vineyard in Verona for Riesling; Sherman Ridge Vineyard in
				Woodstock for Petit Manseng.
			</p>
			<p>
				<strong>WINEMAKING</strong>
				<br />
				Both grapes were whole cluster pressed and cofermented. Juice was left with lees and fermented with
				ambient yeasts in stainless. Wine was left on fermentation lees in tank for 10 months without SO2. Full
				malolactic. Bottled unfiltered with a single 25ppm SO2 addition in August 2023.
			</p>
			<p>
				<strong>CHEMISTRY</strong>
				<br />
				pH: 3.08
				<br />
				TA: 6.9 g/L
				<br />
				Free SO2 at bottling: ~10 ppm
				<br />
				Alcohol: 11.4% abv
				<br />
				RS: 0.95 g/L
				<br />
				Malic Acid: 0.05 g/L
				<br />
				Acetic acid at bottling: 0.56 g/L
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
