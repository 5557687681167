import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2022 Hintermen',
	date: '2024-10-24',
	image: require('./2022-hintermen@2x.png'),
	body: (
		<>
			<p>
				A blend of 62% Petit Manseng and 38% Riesling. 174 cases produced.
			</p>
			<p>
				<strong>FRUIT SOURCES</strong>
				<br />
				Sherman Ridge Petit Manseng, Verona Farm (Noer) Riesling, Oxeye Riesling
			</p>
			<p>
				<strong>WINEMAKING</strong>
				<br />
				Both Rieslings were picked first, directly pressed and combined. The fermentation 
				started with ambient yeast, and then the Petit Manseng was directly pressed and 
				combined with the Riesling before being moved to puncheons with volumes between 
				450L and 600L. Two out of three puncheons were neutral, French oak and one was neutral, 
				French Acacia wood. The wine fermented slowly for over a year and then was transferred 
				to stainless barrels, with the lees, for eight months. The wine aged a total of 19 
				months on the lees before being bottled unfined and unfiltered on 4/11/24 with a single 
				addition of SO2.
			</p>
			<p>
				<strong>CHEMISTRY</strong>
				<br />
				pH: 3.22
				<br />
				TA: 6.8 g/L
				<br />
				Free SO2 at bottling: 12 ppm
				<br />
				Alcohol: 12.9% abv
				<br />
				RS: 1.7 g/L
				<br />
				Malic Acid: {'<'}0.1 g/L
				<br />
				Acetic acid at bottling: 0.78 g/L
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
