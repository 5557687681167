import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2021 Strange Collapse',
	date: '2022-03-25',
	image: require('./2021-strange-collapse@2x.png'),
	body: (
		<>
			<p>
				A blend of 49% Vidal blanc, 31% Petit Manseng, 8% Chambourcin, 8% Noiret, and 4% Traminette. Right off
				the bat the sunrise starburst color tells us this is something different. Not as dark a heart as last
				years but still solidly unique. The nose is more subtle this year as well. Thats no complaint. The fresh
				strawberry and watermelon rind are ever present and appealing. The subtlety continues in the glass as
				well. The soft river stone wetness make for an easy delicious ripper with just enough weight and acid to
				let you know you're alive. As for the label, it is a representation of what it’s like being a loner in a
				gang of thousands. An image of survival in a world that becomes increasingly more crowded and more busy.
				“If this world is gonna burn then everyone should get the chance to light it up!” 740 cases produced.
			</p>
			<p>
				<strong>FRUIT SOURCES</strong>
				<br />
				Sherman Ridge, Bluestone, and Rainbow Hill Vidal blanc, Sherman Ridge Petit Manseng, Bluestone
				Chambourcin, Mowry Spring Noiret, Barren Ridge Traminette, all from the Shenandoah Valley.
			</p>
			<p>
				<strong>WINEMAKING</strong>
				<br />
				Combination of direct press and whole cluster fermentation (six weeks on skins) on Vidal, two day skin
				maceration on Traminette, no SO2 on the fruit, Chambourcin and Noiret on the skins for four days, semi
				carbonic and then pressed and combined with everything else in a big co-fermented varietal soup. It
				finished primary and malo in tank and aged on lees until bottling.
			</p>
			<p>
				<strong>CHEMISTRY</strong>
				<br />
				pH: 3.44
				<br />
				TA: 5.4 g/L
				<br />
				Free SO2 at bottling: 8 ppm
				<br />
				Alcohol: 11.8% abv
				<br />
				RS: 0.84 g/L
				<br />
				Malic Acid: {'<'}0.31 g/L
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
