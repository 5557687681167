import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2019 Los Idiots',
	date: '2021-09-06',
	image: require('./2019-los-idiots@2x.png'),
	body: (
		<>
			<p>
				A blend of 40% Blaufränkisch, 39% Riesling, 11% Cabernet Franc, and 10% Petit Manseng. Black cherry and
				blueberry dominate the nose. The fruit has a wild sort of flavor, like earthiness and fruitiness had a
				baby and it tasted good. Super soft and luxe on the palate, very easy drinking. No need for too much air
				on this one, just chill a bit and go! 59 cases produced.
			</p>
			<p>
				<strong>FRUIT SOURCES</strong>
				<br />
				Mount Airy Vineyard in Fort Defiance, growers Tim and Ben Jordan; Sherman Ridge Vineyard n Woodstock,
				grower Sunset Hills
			</p>
			<p>
				<strong>WINEMAKING</strong>
				<br />
				100% Whole cluster cofermentation. We blended a touch of the CF and PM in Dos into this to soften the
				acidity and fill it out. Aged for 11 months in neutral French 228L barrels. Bottled unfiltered August
				2020.
			</p>
			<p>
				<strong>CHEMISTRY</strong>
				<br />
				pH: 3.43
				<br />
				TA: 5.72 g/L
				<br />
				Free SO2 at bottling: 8 ppm
				<br />
				Alcohol: 11.6% abv
				<br />
				RS: 0.14 g/L
				<br />
				Malic Acid: 0.02 g/L
				<br />
				Acetic acid at bottling: 0.61 g/L
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
