import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2020 The Weird Ones Are Wolves',
	date: '2022-09-27',
	image: require('./2020-the-weird-ones-are-wolves@2x.png'),
	body: (
		<>
			<p>
				A blend of 85% Cabernet Franc and 15% Petit Manseng. In the glass it appears to be a bit lighter, like a
				pinot. It makes sense given the cooler vintage. The nose is vibrant and full of life. Bright, fresh red
				fruits dominate. On the palate, fresh white cherry and fresh strawberry mix with lavender and white
				pepper to make a strong impression. Given the vintage, it shows lots of heft and muscle and will age
				very well with its lively acidity. 256 cases produced.
			</p>
			<p>
				<strong>FRUIT SOURCES</strong>
				<br />
				Baer Ridge Vineyard in Timberville, grower Jody Dodson; Sherman Ridge Vineyard n Woodstock, grower
				Sunset Hills
			</p>
			<p>
				<strong>WINEMAKING</strong>
				<br />
				Both grapes were co-fermented with 15% Whole cluster, the Petit Manseng being half direct press and half
				whole cluster. Aged for 11 months in neutral French 500L and 228L barrels. Bottled unfiltered August
				2020.
			</p>
			<p>
				<strong>CHEMISTRY</strong>
				<br />
				pH: 3.88
				<br />
				TA: 4.6 g/L
				<br />
				Free SO2 at bottling: 19 ppm (41 ppm total)
				<br />
				Alcohol: 13.3% abv
				<br />
				RS: 0.15 g/L
				<br />
				Malic Acid: 0.01 g/L
				<br />
				Acetic acid at bottling: 0.54 g/L
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
