import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2018 The Weird Ones Are Wolves',
	date: '2020-11-11',
	image: require('./2018-the-weird-ones-are-wolves@2x.png'),
	body: (
		<>
			<p>
				A blend of 87% Cabernet Franc, 6% Petit Manseng, 4% Riesling, and 3% Blaufränkisch. Dark, a lot darker
				than what I expected given the vintage. The nose is like one of those chocolate covered cherries my mom
				used to eat. The fruit is so sweet it needs the chalky, ashiness and lavender accents to balance it. The
				smokey, wet charcoal mid palate nicely complemented by the dried rose petal finish. There’s an earthy
				depth that rounds out the finish, yet still leaves you with sweet red/black fruit. 152 cases produced.
			</p>
			<p>
				<strong>FRUIT SOURCES</strong>
				<br />
				Baer Ridge Vineyard in Timberville, grower Jody Dodson; Great Valley Vineyard in Natural Bridge, grower
				Nathan Bailey; Rosedale Vineyard in Stuarts Draft, grower Bill Tonkins; Mount Airy Vineyard in Fort
				Defiance, growers Tim and Ben Jordan.
			</p>
			<p>
				<strong>WINEMAKING</strong>
				<br />
				Co-fermented with 10% whole cluster with ambient yeasts. Aged for 10 months in neutral French 228L
				barrels. Bottled unfiltered July 2019.
			</p>
			<p>
				<strong>CHEMISTRY</strong>
				<br />
				pH: 3.85
				<br />
				TA: 4.9 g/L
				<br />
				Free SO2 at bottling: 22 ppm
				<br />
				Alcohol: 12.6% abv
				<br />
				RS: 0.1 g/L
				<br />
				Malic Acid: 0.04 g/L
				<br />
				Acetic acid at bottling: 0.64 g/L
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
