import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2023 Marsha',
	date: '2024-10-24',
	image: require('./2023-marsha@2x.png'),
	body: (
		<>
			<p>
				{' '}<a href="https://www.cofermented.com" target="_blank">Oniyx Acosta of Cofermented</a>: 
				Introducing Marsha and Sylvia 💛 A co-fermentation 
				of 68% Vidal Blanc, 21% Petit Manseng, 7% Traminette, and 4% Riesling. An 
				homage to two amazing individuals that have paved the path for so many 
				of us within the LGBTQIA+ community.  
			</p>
			<p>
				Marsha — Infusion B, is a light bubbly sparkling wine infused with 
				Wine Berries and local Virginia Wild Flower honey 🍯
			</p>
			<p>
				$1 per bottle will be donated to Co-Fermented to continue growing 
				efforts for the LGBTQIA+ community within the wine industry 🏳️‍🌈 🥂
			</p>
			<p>
				Thank you to @rtiiika for capturing Marsha and Sylvia’s essence in your 
				beautiful artwork and for being apart of this project. They are looking 
				for more wine projects to be apart of 🎨! Grateful for {' '} 
				<a href="https://www.instagram.com/abvferments/" target="_blank">@abvferments</a> 
				{' '} <a href="https://www.instagram.com/jahdemarley.drinks/" target="_blank">@jahdemarley.drinks </a> for 
				being the connector in getting this project started 🏳️‍🌈❣️

			</p>
			<p>
				This couldn’t have been possible without community and this work can’t 
				continue to happen without community. Thank you to all who have been apart 
				of this project and continue to support. 23 cases produced.
			</p>
			<p>
				<strong>FRUIT SOURCES</strong>
				<br />
				Rainbow Hill Vidal Blanc, Sherman Ridge Petit Manseng, Middlebrook 
				Traminette, Oxeye Riesling, pine cones foraged from Leaport hillside 
				in Verona.
			</p>
			<p>
				<strong>WINEMAKING</strong>
				<br />
				We co-fermented the grapes in neutral barrels with no additions. 
				After completion of malo, we macerated the foraged wine berries 
				in the wine. At bottling, we added wild flower honey to provide 
				the yeast with sugar to create the bubbles under the crown cap. 
				We bottled the wine unfined and unfiltered with no added sulfur 
				dioxide.
			</p>
			<p>
				<strong>CHEMISTRY</strong>
				<br />
				pH: 3.15
				<br />
				TA: 6.6 g/L
				<br />
				Free SO2 at bottling: None
				<br />
				Alcohol: 12.1% abv
				<br />
				RS: 0.8 g/L
				<br />
				Malic Acid: {'<'}0.1 g/L
				<br />
				Acetic acid at bottling: 0.6 g/L
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
