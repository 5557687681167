import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2022 The Weird Ones Are Wolves',
	date: '2024-10-24',
	image: require('./2022-the-weird-ones-are-wolves@2x.png'),
	body: (
		<>
			<p>
				A blend of 83% Cabernet Franc and 17% Petit Manseng. 192 cases produced.
			</p>
			<p>
				<strong>FRUIT SOURCES</strong>
				<br />
				Baer Ridge Cabernet Franc, Sherman Ridge Petit Manseng
			</p>
			<p>
				<strong>WINEMAKING</strong>
				<br />
				The Petit Manseng was picked and directly pressed after which the 
				fermentation started with ambient yeasts. It was then added to the 
				Cabernet Franc which was 15% whole cluster and the rest destemmed. 
				After pressing the wines underwent malo and was aged mostly in 500L, 
				neutral, French oak puncheons and one 228L neutral barrel.The wine 
				was bottled unfiltered on 8/2/2023
			</p>
			<p>
				<strong>CHEMISTRY</strong>
				<br />
				pH: 3.88
				<br />
				TA: 4.27 g/L
				<br />
				Free SO2 at bottling: 17 ppm
				<br />
				Alcohol: 13.2% abv
				<br />
				RS: {'<'}0.5 g/L
				<br />
				Malic Acid: {'<'}0.1 g/L
				<br />
				Acetic acid at bottling: 0.52 g/L
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
