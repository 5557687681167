import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2021 Dos Idiots',
	date: '2023-10-10',
	image: require('./2021-dos-idiots@2x.png'),
	body: (
		<>
			<p>
				A blend of 50% Cabernet Franc and 50% Petit Manseng. Sweet cherry and baking spices make this wine feel
				warm and rich on the nose. The color in glass tells a different story however. An equal part split of
				Cab Franc and Petit Manseng was a brilliant move. All the green stemminess that comes along with
				youthful Cab Franc is gently massaged by the elegant and soft fruited P Manseng. A full bodied red wine
				in a seersucker suit with no socks on vibe. 140 cases produced.
			</p>
			<p>
				<strong>FRUIT SOURCES</strong>
				<br />
				Bethany Ridge Vineyards for both Petit Manseng and Cabernet Franc, Farmed by Walsh Family
			</p>
			<p>
				<strong>WINEMAKING</strong>
				<br />
				We took what we learned from Wolves and Los Idiots and came up with a theory that created Dos Idiots. In
				terms of the red/white co-ferment approach, this wine is evolving. While 2020 saw all the Petit Manseng
				on the skins, in 2021 we direct pressed half of it. The other half was added whole cluster and the
				Cabernet Franc was de-stemmed before the grapes were co-fermented. We ended up pressing before the
				primary fermentation was finished, and the wine went to barrel to finish. This resulted in some action
				from some brettanomyces, giving this wine a bit more wildness and volatility than usual. Aged for 11
				months in neutral French 228L barrels.
			</p>
			<p>
				<strong>CHEMISTRY</strong>
				<br />
				pH: 3.78
				<br />
				TA: 6.3 g/L
				<br />
				Free SO2 at bottling: 17 ppm
				<br />
				Alcohol: 13.5% abv
				<br />
				RS: 0.4 g/L
				<br />
				Malic Acid: 0.05 g/L
				<br />
				Acetic acid at bottling: 1.02 g/L
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
