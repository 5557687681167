import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2017 Goodbye Horses',
	date: '2019-11-01',
	image: require('./2017-goodbye-horses@2x.png'),
	body: (
		<>
			<p>
				100% Riesling. “I’m back bitches!” OhhhWEEEEE That’s on the move. The acid is nervous with this one.
				Peel your wig back acid, y’all. Zingy, Zesty, Zippy and all the other Z words. This wine sings, it
				screams, it breaks glasses. Like crunching hippie crystals in your teeth, it will keep you craving more.
				91 cases produced.
			</p>
			<p>
				<strong>FRUIT SOURCES</strong>
				<br />
				Shenandoah Vineyards
				<br />
				Riesling (54%), <br />
				Ox-Eye Riesling (46%)
			</p>
			<p>
				<strong>WINEMAKING</strong>
				<br />
				Whole cluster pressed and minimally settled to retain fine juice lees. Fermented in a combination of
				stainless and neutral French oak, 228L and 300L barrels. Aged on the lees for 9 months. Bottled 5/31/18
				with no fining or tartrate stabilization.
			</p>
			<p>
				<strong>CHEMISTRY</strong>
				<br />
				pH: 3.39
				<br />
				TA: 7.7 g/L
				<br />
				Free SO2 at bottling: 22 ppm
				<br />
				Alcohol: 10.9% abv
				<br />
				RS: 1.68 g/L
				<br />
				Malic Acid: 2.09 g/L
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
