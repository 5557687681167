import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2020 Elon Must',
	date: '2022-09-27',
	image: require('./2020-elon-must@2x.png'),
	body: (
		<>
			<p>
				A blend of 41% Blaufränkisch, 31% Traminette, 21% Vidal Blanc, 4% Chambourcin and 3% Petit Manseng. If
				we were ever to take a stab at Beaujolais Nouveau, this is it. Bright and high energy (like us!) and
				full of dry, crunchy red fruit. Cranberry, crabapple and watermelon rind all over the nose and palate.
				There's a subtle herby-citrus on the palate as well. A slight chill brings out the brambly red fruit
				that blaufrankisch is famed for. This is perfect for this time of year – sippin outside with your crew,
				bonfire, music, you see it, we know you do. 34 cases produced.
			</p>
			<p>
				<strong>FRUIT SOURCES</strong>
				<br />
				Oxeye Blaufrankisch, Barren Ridge Traminette, Bluestone Chambourcin, Sherman Ridge Vidal blanc and Petit
				Manseng, all from the Shenandoah Valley
			</p>
			<p>
				<strong>WINEMAKING</strong>
				<br />
				This has nothing to do with the winemaking , but I do want to make clear that I (Ben) am not an Elon fan
				boy. There may or may not be folks involved with Lightwell who are enthralled, waiting for his next
				move, but my stance is that we are messing with the guy with this wine name and banking on the fact that
				we made too little to be cease and desisted. As for the winemaking: Traminette can be a little much, but
				sometimes its the best thing to do with the loud, aromatic grapes is to lean into them and macerate
				them, full on skin contact. This is a rambling red/white coferment, and the Traminette waving
				frantically at you, but in a good way. Nothing added until a bit of SO2 at bottling.
			</p>
			<p>
				<strong>CHEMISTRY</strong>
				<br />
				pH: 3.7
				<br />
				TA: 5.4 g/L
				<br />
				Free SO2 at bottling: 8 ppm (27 ppm total)
				<br />
				Alcohol: 10.7% abv
				<br />
				RS: 0.2 g/L
				<br />
				Malic Acid: 0.01 g/L
				<br />
				Acetic acid at bottling: 0.51 g/L
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
