import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2016 Hintermen',
	date: '2018-11-01',
	image: require('./2016-hintermen@2x.png'),
	body: (
		<>
			<p>
				A blend of 72% Riesling and 28% Petit Manseng. Has the hunter already become the hunted? As the
				hintermen march on the untamed wild pushes back as the once peaceful scenery turns territorial towards
				the uninvited travelers and the wildlife begins to come to life. The risk and reward of these uncharted
				lands might both be too great to turn back now.
			</p>
			<p>
				“Holy Shit!” This is somehow at the same time full and fleshy while bursting with minerality and
				acidity. Built to age like last year but for different reasons. This is racy and sleek like that silver
				Delorian you’ve been been dreaming of since the 80’s. Somewhere between old school wax candies filled
				with weird juice and Warheads, this is a visceral experience. Hold on to your seat. 165 cases produced.
			</p>
			<p>
				<strong>FRUIT SOURCES</strong>
				<br />
				Sourced from the Shenandoah Valley. Ox-Eye Riesling and Rosedale Petit Manseng.
			</p>
			<p>
				<strong>WINEMAKING</strong>
				<br />
				Simple. Foot tread then whole cluster pressed. Juice racked relatively dirty and co-fermented with
				ambient yeasts in neutral 500L puncheon and neutral 228L barrels. Wine left on gross lees for 15 months
				with low level of SO2. No malolactic. Bottled January 2018.
			</p>
			<p>
				<strong>CHEMISTRY</strong>
				<br />
				pH: 3.34
				<br />
				TA: 5.81 g/L
				<br />
				Free SO2 at bottling: 18 ppm
				<br />
				Alcohol: 12.1% abv
				<br />
				RS: 1.04 g/L (0.1%)
				<br />
				Malic Acid: 1.77 g/L
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
