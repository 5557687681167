import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2020 Dos Idiots',
	date: '2022-09-27',
	image: require('./2020-dos-idiots@2x.png'),
	body: (
		<>
			<p>
				A blend of 52% Cabernet Franc and 48% Petit Manseng. We’re immediately intrigued by the color in the
				glass. Almost like older Nebbiolo, lighter with rusty undertones. Oddly enough that sort of translates
				to the nose which was very subtle and savory. All that went out the window when tasted. This is
				certainly Sebastian’s favorite red of the year. The complexity is what gets him. The palate is elegant
				and rich with ashy tannins that know just when to let go. The fruits (ripe strawberry, plum) are cooked
				with baking spice and pipe tobacco. 211 cases produced.
			</p>
			<p>
				<strong>FRUIT SOURCES</strong>
				<br />
				Twin Notch
			</p>
			<p>
				<strong>WINEMAKING</strong>
				<br />
				We took what we learned from Wolves and Los Idiots and came up with a theory that created Dos Idiots. In
				terms of the red wine meets orange wine approach, this shows a bit more of the orange here than in Los
				Idiots, maybe because 42% Petit Manseng means the grape will definitely have an impact. Petit Manseng
				was added whole cluster and the Cabernet Franc was destemmed before the grapes were cofermented. Aged
				for 11 months in neutral French 228L barrels.
			</p>
			<p>
				<strong>CHEMISTRY</strong>
				<br />
				pH: 3.98
				<br />
				TA: 4.8 g/L
				<br />
				Free SO2 at bottling: 12 ppm (46 ppm total)
				<br />
				Alcohol: 13.2% abv
				<br />
				RS: 0.2 g/L
				<br />
				Malic Acid: 0.02 g/L
				<br />
				Acetic acid at bottling: 0.56 g/L
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
