import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2024 Hellawine Release Party',
	date: '2024-09-14',
	image: require('./2024-hellawine@2x.jpg'),
	body: (
		<>
			<p>Isn’t it rich? Every year on the eve of an October day, the freaks – the wild, and adventurous ones of the Shenandoah Valley – come out to revel in the moonlight and take the introductory sips of the forefront with new wines from Lightwell Survey. This annual cabal is known in lore by the name of HELLAWINE! Where are the Clowns?</p>
			<p>On Saturday, October 26 from 6pm - 10pm, we’ll hoist open the doors of the tasting room for you to slip on your October’s best. Meet us here, at last on the ground as we come to celebrate in our little town. A night of bliss, will be ours as we frolic in the eve, and laugh as our cares wane and our spirits rise.</p>
			<p>There will be eats, drinks and there ought to be clowns. But what we need is you, to make your entrance with your usual flair, to return or appear in the eve as you keep tearing around.</p>
			<p>It’s but once a year, and it's arrival is near it’s HELLAWINE time, aren’t we a pair?</p>
			<p>
				<strong>WHEN</strong><br />
				2024 Hellawine - “Send In The Clowns”<br />
				October 26th from 6pm - 10pm<br />
				$35 per person - Tickets also available at the door<br />
				Ring Leader wine club members get in for FREE<br />

			</p>
			<p>
				<strong>WHERE</strong><br />
				Lightwell Survey Winery & Tasting Room (The Mill at South River Complex)<br />
				200 W 12TH ST, UNIT# D53<br />
				Waynesboro, VA 22980<br />
			</p>
			<p>
				<strong>QUESTIONS/CONTACT</strong><br />
				<a href="mailto:wines@lightwellsurvey.com">wines@lightwellsurvey.com</a>
			</p>
			</>
		),
		cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
	}
