import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2022 Between the Dark and the Light',
	date: '2023-05-05',
	image: require('./2022-between-the-dark-and-the-light@2x.png'),
	body: (
		<>
			<p>A blend of 67% Grapes, 33% Apples. 75 cases produced.</p>
			<p>
				<strong>FRUIT SOURCES</strong>
				<br />
				Bluestone Vineyard in Bridgewater for Chambourcin, Rainbow Hill Vineyard in Harrisonburg for Vidal
				blanc, Sherman Ridge Vineyard in Woodstock for Vidal blanc, Countryside Farm fro Ashmead’s Kernel
				apples.
			</p>
			<p>
				<strong>WINEMAKING</strong>
				<br />
				Chambourcin was put in whole cluster with direct press Vidal blanc to begin the fermentation. About
				halfway through, apples were pressed on top of the grapes and the fermentation finished a week later.
				Malolactic and aging occurred in neutral barrel and this was bottled unfiltered with a small SO2
				addition in March 2023.
			</p>
			<p>
				<strong>CHEMISTRY</strong>
				<br />
				pH: 3.73
				<br />
				TA: 5.4 g/L
				<br />
				Free SO2 at bottling: 13 ppm
				<br />
				Alcohol: 10.0% abv
				<br />
				RS: 0.5 g/L
				<br />
				Malic Acid: 0.05 g/L
				<br />
				Acetic acid at bottling: 0.43 g/L
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
